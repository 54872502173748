import React, { FC } from "react";
import styled from "styled-components";
import SubTitle from "../../atoms/subTitle";
import ActionButton from "../../molecules/actionbutton";
import { compareByPresentationOrder } from "../../../utils/misc";
import {
  LanguageSelectionHandler,
  LanguagesWithBackend,
  LanguageSelectionHandlerProps,
} from "./decorators";
import { languages, chooseLanguages } from "../../../translations/languages";

export type LanguageIconType = {
  publicURL: string;
  name: string;
  ext: string;
  fullName?: string;
};

export type Language = {
  name: string;
  code: string;
  flag: { type: string; filename_download: string };
  presentationOrder: number;
  hasQa: boolean;
};

export type LanguagesType = Array<Language>;

export type LanguagesProps = {
  languages: LanguagesType;
  languageIcons: LanguageIconType[];
  omitLanguageCodes?: Array<string>;
  onLanguageSelected: (languageCode: string) => void;
};

type LanguageItemProps = {
  languageCode: string;
  languageLabel: string;
  ariaLabel?: string;
  languageIconUrl?: string;
  onLanguageSelected: (languageCode: string) => void;
};

const LanguagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
  grid-gap: 1rem;
`;

const LanguageItem: FC<LanguageItemProps> = ({
  languageCode,
  ariaLabel,
  languageLabel,
  languageIconUrl,
  onLanguageSelected,
}) => {
  return (
    <ActionButton
      role="menuitem"
      action="selectAndNavigate"
      aria-label={ariaLabel}
      contentLeft={
        <img
          aria-hidden
          src={languageIconUrl}
          style={{
            height: "1em",
            margin: 0,
          }}
          alt={`${languageLabel}`}
        />
      }
      contentCenter={languageLabel}
      onClick={() => onLanguageSelected(languageCode)}
    >
      {languageLabel}
    </ActionButton>
  );
};

const SubTitles = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 2rem;

  & > * {
    font-weight: 300;
    margin-right: 1rem;
  }
`;

export const Languages = ({
  languages,
  languageIcons,
  omitLanguageCodes = [],
  onLanguageSelected,
}: LanguagesProps) => {

  return (
    <div style={{ width: "100%", maxWidth: "65rem" }}>
      <h3 lang="en-GB">Choose language</h3>
      <SubTitles>
        <SubTitle ariaHidden="true" lang="da-DK">Vælg sprog</SubTitle>
        <SubTitle ariaHidden="true" lang="is-IS">Veldu tungumál</SubTitle>
        <SubTitle ariaHidden="true" lang="no-NO">Velg språk</SubTitle>
        <SubTitle ariaHidden="true" lang="fi-FI">Valitse kieli</SubTitle>
        <SubTitle ariaHidden="true" lang="sv-SE">Välj språk</SubTitle>
      </SubTitles>
      <LanguagesContainer role="menu" aria-label="Choose language" tabIndex={0}>
        {languages
          .filter(({ code }) => !omitLanguageCodes.find((o) => o === code))
          .sort(compareByPresentationOrder)
          .map(({ name, code, flag: { filename_download } }) => {
            const iconDefinition = languageIcons.find(
              (l) => l.fullName === filename_download
            );
            const title = chooseLanguages[code]?.text || "";
            return (
              <LanguageItem
                languageCode={code}
                languageLabel={name}
                ariaLabel={`${title}: ${name}`}
                languageIconUrl={iconDefinition?.publicURL}
                onLanguageSelected={onLanguageSelected}
                key={code}
              />
            );
          })}
      </LanguagesContainer>
    </div>
  );
};

type WithSelectionHandlerAndBackendProps = Omit<
  LanguagesProps,
  "languages" | "languageIcons" | "onLanguageSelected"
> &
  Pick<LanguageSelectionHandlerProps, "navigateTo">;

const WithSelectionHandlerAndBackend: FC<WithSelectionHandlerAndBackendProps> = ({
  navigateTo,
  ...rest
}: WithSelectionHandlerAndBackendProps) => {
  return (
    <LanguagesWithBackend
      render={({ languages, languageIcons }) => (
        <LanguageSelectionHandler
          languages={languages}
          navigateTo={navigateTo}
          render={({ onLanguageSelected }) => (
            <Languages
              languages={languages}
              languageIcons={languageIcons}
              onLanguageSelected={onLanguageSelected}
              {...rest}
            />
          )}
        />
      )}
    />
  );
};

export default WithSelectionHandlerAndBackend;
