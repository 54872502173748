import React, { useEffect, useState } from "react";
import { Main, PageContainer } from "../components/atoms/pageContent";
import Header from "../components/atoms/header";
import { Footer } from "../components/molecules/footer";
import NavigationBar from "../components/molecules/navigationBar";
import Languages from "../components/organisms/languages";
import { getTranslation } from "../translations/header";
import { TranslationKey } from "../translations/languages";
import { LanguageProvider } from "../utils/contexts/languageContext";
import SEO from "../components/atoms/seo";
import {LinkToContent, contentId} from "../components/atoms/contentlink";
import useIsClient from "../utils/hooks/isClient"

const LanguagePage = () => {
  const { isClient, key } = useIsClient();
  const [{ header, subTitle }, setHeader] = useState({
    header: "",
    subTitle: "",
  });

  useEffect(() => {
    const lang: TranslationKey = "en-GB";
    const header = getTranslation(lang, "header");
    const subTitle = getTranslation(lang, "subTitle");
    setHeader({ header, subTitle });
  }, [setHeader]);

  if ( !isClient ) return null;
  return (
    <PageContainer key={key}>
      <SEO />
      <div>
        {/* Pass English here as language as language will not be chosen here yet */}
        <LinkToContent languageCode="en-GB"/>
        <NavigationBar />
        <div id={contentId}>
          <Header heading={header} subtitle={subTitle} />
        </div>
      </div>
      <Main >
        <LanguageProvider >
          <Languages navigateTo="/" />
        </LanguageProvider>
      </Main>
      <Footer links={[]} />
    </PageContainer>
  );
};

export default LanguagePage;
