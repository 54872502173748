import React, { FC } from "react";
import styled, { css } from "styled-components";
import { options, colorThemes, rhythm } from "../../utils/typography";

const { header: headerTheme } = colorThemes;

const Container = styled.header`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 0.5rem 0 2rem 0;
  background: ${headerTheme.primary};
  color: ${options.headerColor};
  font-family: ${options.bodyFontFamily?.join(",")};
  min-height: 15vh;
  & > * {
    margin-bottom: ${rhythm(1.5)};
    max-width: 60vw;
  }
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export type HeaderProps = {
  heading: React.ReactNode;
  subtitle?: React.ReactNode;
};

const Header: FC<HeaderProps> = ({ heading, subtitle }) => {
  return (
    <Container>
      {<h1>{heading}</h1>}
      {subtitle && <div>{subtitle}</div>}
    </Container>
  );
};

export default Header;
